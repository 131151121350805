var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"all-formbuilders-view"},[_c('h1',{staticStyle:{"font-weight":"600"}},[_vm._v("My Formbuilders")]),_c('div',{staticClass:"vue-data-table-default"},[_c('div',[(!_vm.getIsMobile)?_c('data-tables-server',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.allFormbuilders,"current-page":_vm.currentPage,"page-size":_vm.pageSize,"pagination-props":_vm.paginationPropsForTable,"total":_vm.total},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"query-change":_vm.loadData,"sort-change":_vm.sortChange,"change":_vm.loadData}},[_c('el-table-column',{attrs:{"label":"Name","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"el-icon-notebook-2"}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(scope.row.formbuilders_details_id.name))])]}}],null,false,3594174012)}),_c('el-table-column',{attrs:{"label":"Created at","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"el-icon-time"}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm._f("globalDateTimeFormat")(scope.row.created_at)))])]}}],null,false,2159138391)}),_c('el-table-column',{attrs:{"label":"Updated at","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"el-icon-time"}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm._f("globalDateTimeFormat")(scope.row.updated_at)))])]}}],null,false,177919426)}),_c('el-table-column',{attrs:{"label":"Completion Status","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"warning"},[(
                  scope.row.formbuilder_data_status == 'PENDING' ||
                  scope.row.formbuilder_data_status == 'SENT'
                )?_c('i',{staticClass:"el-icon-refresh"}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(scope.row.formbuilder_data_status == "PENDING" || scope.row.formbuilder_data_status == "SENT" ? "Pending" : "")+" ")])]),_c('div',{staticClass:"success"},[(scope.row.formbuilder_data_status == 'COMPLETED')?_c('i',{staticClass:"el-icon-success"}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(scope.row.formbuilder_data_status == "COMPLETED" ? "Completed" : "")+" ")])])]}}],null,false,3581107995)})],1):_vm._e()],1)]),_c('div',[(_vm.getIsMobile)?_c('data-tables-server',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.allFormbuilders,"current-page":_vm.currentPage,"page-size":_vm.pageSize,"pagination-props":_vm.paginationPropsForTable,"total":_vm.total},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"query-change":_vm.loadData,"sort-change":_vm.sortChange,"change":_vm.loadData}},[_c('el-table-column',{attrs:{"label":"Name","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"el-icon-notebook-2"}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(scope.row.formbuilders_details_id.name))])]}}],null,false,3594174012)}),_c('el-table-column',{attrs:{"label":"Completion Status"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"warning"},[(
                scope.row.formbuilder_data_status == 'PENDING' ||
                scope.row.formbuilder_data_status == 'SENT'
              )?_c('i',{staticClass:"el-icon-refresh"}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(scope.row.formbuilder_data_status == "PENDING" || scope.row.formbuilder_data_status == "SENT" ? "Pending" : "")+" ")])]),_c('div',{staticClass:"success"},[(scope.row.formbuilder_data_status == 'COMPLETED')?_c('i',{staticClass:"el-icon-success"}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(scope.row.formbuilder_data_status == "COMPLETED" ? "Completed" : "")+" ")])])]}}],null,false,1401629979)}),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"expand-column-item"},[_c('div',{staticClass:"expand-column-item-label"},[_vm._v("Created at:")]),_c('div',{staticClass:"expand-column-item-content"},[(scope.row.created_at)?[_c('i',{staticClass:"el-icon-time"}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateTimeFormat")(scope.row.created_at))+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"expand-column-item"},[_c('div',{staticClass:"expand-column-item-label"},[_vm._v("Updated at:")]),_c('div',{staticClass:"expand-column-item-content"},[(scope.row.updated_at)?[_c('i',{staticClass:"el-icon-time"}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateTimeFormat")(scope.row.updated_at))+" ")])]:_vm._e()],2)])]}}],null,false,283571169)})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }